import { useEffect, useState, useContext } from "react";
import { useWeb3React } from "@web3-react/core";
import { provider, walletconnect, face, connectorByName } from "../connectors/connectors";
import { addressShort } from "../helpers/Formatter";
import { AuthContext } from "./AuthProvider";

export function Account() {
	const { account, activate, deactivate, active, library, chainId } = useWeb3React();
	const { connectWallet, disconnectWallet, user } = useContext(AuthContext);
	const [open, setOpen] = useState(false);
	const [address, setAddress] = useState();
	const [error, setError] = useState();

	useEffect(() => {
		if (account && active && library && chainId) {
			setAddress(account);
			connectWallet({
				wallet: sessionStorage.getItem("wallet"),
				address: account,
			});
			setOpen(false);
			sessionStorage.setItem("account", account);
			if (typeof window.ethereum !== "undefined") {
				window.ethereum.on("disconnect", () => {
					sessionStorage.removeItem("account");
					sessionStorage.removeItem("wallet");
					console.log("disConnect");
				});
				window.ethereum.on("chainChanged", (_chainId) => {
					window.location.reload();
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, active, library, chainId]);

	useEffect(() => {
		if (user) {
			setAddress(user.address);
			setOpen(false);
		}
	}, [user]);

	const connect = async (connector, wallet) => {
		switch (wallet) {
			case "WalletConnect":
			case "AMLSafe":
				// const session = await provider.connect({
				//     namespaces: {
				//         eip155: {
				//             methods: [
				//                 "eth_sendTransaction",
				//                 "eth_signTransaction",
				//                 "eth_sign",
				//                 "personal_sign",
				//                 "eth_signTypedData",
				//             ],
				//             chains: [`eip155:${chainId}`],
				//             events: ["chainChanged", "accountsChanged"],
				//         },
				//     },
				// });
				const _accounts = await provider.enable();
				setAddress(_accounts[0]);
				connectWallet({
					wallet: wallet,
					address: _accounts[0],
				});
				break;
			case "OKXWallet":
				if (typeof window.okexchain !== "undefined") {
					const accounts = await window.okexchain.request({ method: "eth_requestAccounts" });
					setAddress(accounts[0]);
					connectWallet({
						wallet: wallet,
						address: accounts[0],
					});
				} else {
					setError("Please install OKX Wallet");
					setTimeout(() => {
						setError("");
					}, 3000);
				}
				break;
			case "ParticleNetwork":
				connector.auth.login().then((userInfo) => {
					const address = userInfo.wallets[0].public_address;
					setAddress(address);
					connectWallet({
						wallet: wallet,
						address: address,
					});
				});
				break;
			case "faceWallet":
				await connector.auth.login();
				const address = await connector.getAddresses();
				setAddress(address[0]);
				connectWallet({
					wallet: wallet,
					address: address[0],
				});
				break;
			case "HaloWallet":
				if (typeof window.kucoin !== "undefined") {
					const kucoinAccounts = await window.kucoin.request({ method: "eth_requestAccounts" });
					setAddress(kucoinAccounts[0]);
					connectWallet({
						wallet: wallet,
						address: kucoinAccounts[0],
					});
				} else {
					setError("Please install Halo Wallet");
					setTimeout(() => {
						setError("");
					}, 3000);
				}
				break;
			default:
				if (typeof window.ethereum !== "undefined") {
					activate(connector);
				} else {
					setError("Please use Wallet Connect or access this page from your wallet's in-app browser");
					setTimeout(() => {
						setError("");
					}, 2000);
				}
				break;
		}
		sessionStorage.setItem("wallet", wallet);
	};

	const disconnect = async () => {
		if (sessionStorage.getItem("wallet") === "WalletConnect") walletconnect.killSession();
		if (sessionStorage.getItem("wallet") === "faceWallet") await face.auth.logout();
		deactivate();
		sessionStorage.removeItem("account");
		sessionStorage.removeItem("wallet");
		disconnectWallet();
		window.location.reload();
	};

	const showFaceWallet = async () => {
		if (sessionStorage.getItem("wallet") === "faceWallet") {
			await face.wallet.home();
		}
	};

	const SVGIconClose = () => {
		return (
			<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
			</svg>
		);
	};

	return (
		<>
			<button className="connect-wallet" onClick={() => setOpen(true)}>
				{address ? addressShort(address) : "Connect Wallet"}
			</button>
			<div className={"modal-overlay" + (open ? " open" : "")} onClick={() => (open ? setOpen(false) : setOpen(true))}>
				<div className="modal-container">
					<div className="modal-box w-full mx-auto my-4 text-center bg-white rounded-lg" onClick={(e) => e.stopPropagation()}>
						<div className="relative p-4">
							<button className="modal-close absolute top-4 right-4 text-xl font-bold cursor-pointer" onClick={() => setOpen(false)}>
								<div className="w-5 h-5">{SVGIconClose()}</div>
							</button>
							<div className="modal-title mb-3 text-xl font-bold">{!!address ? "Account Detail" : "Connect Wallet"}</div>
							{error && (
								<div className="wallet-error text-sm my-5">
									<div className="-mb-1 text-black">Error occured!</div>
									<div className="text-red-500">{error}</div>
								</div>
							)}
							{!!address ? (
								<>
									<div className="block text-gray-800">
										<p className="text-gray-800 font-bold">My Address :</p>
										<div className="text-blue-500 cursor-pointer" onClick={showFaceWallet}>
											{window.innerWidth < 500 ? addressShort(address) : address}
										</div>
										{face.auth.isLoggedIn() && sessionStorage.getItem("wallet") === "faceWallet" && <div className="text-sm text-black italic">*click address to show assets</div>}
									</div>
									<div className="text-center mt-4">
										<div className="inline-block cursor-pointer" onClick={disconnect}>
											<div className="flex items-center">
												<span className="inline-block mr-1 text-black font-bold disconnect">Disconnect</span>
												<span className="inline-block w-4 h-4">
													<img src="/img/wallets/disconnect.svg" width={50} height={50} alt="disconnect-icon" />
												</span>
											</div>
										</div>
									</div>
								</>
							) : (
								<div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
									<div
											className="modal-connector !px-0 !py-3 cursor-pointer"
										onClick={() => connect(connectorByName.ParticleNetwork.connector, "ParticleNetwork")}
									>
										<div className="text-sm text-black font-bold">Create new wallet</div>
											<p className="text-[8px] my-1 text-black italic">Powered by Particle Network</p>
											<img src="/img/wallets/particleNetwork.svg" alt="particle-icon" className="mx-auto h-5 w-5" />

									</div>
									{Object.keys(connectorByName).map((wallet, i) => {
										const currentConnector = connectorByName[wallet];
										return (
											<div key={i} onClick={() => connect(currentConnector.connector, wallet)} className="modal-connector relative text-center cursor-pointer">
												<div className="mb-2 text-xs text-black font-bold">{currentConnector.name}</div>
												<img src={currentConnector.icon} alt={currentConnector.name} className={"mx-auto " + currentConnector.style} />
											</div>
										);
									})}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
