import axios from "axios";

const baseUrl = "https://api.kommunitas.net/v1/earn/";
const headerToken = "0xC004e2318722EA2b15499D6375905d75Ee5390B8";
const header = {
	headers: { "X-KOM-TOKEN": headerToken },
};

const getQuarterly = async (address = null) => {
	try {
		const response = await axios.get(baseUrl + "quarterly/?address=" + address, header);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

const getMonthly = async (address = null) => {
	try {
		const response = await axios.get(baseUrl + "monthly/?address=" + address, header);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

const getAirdropByStatus = async (status, address = null) => {
	try {
		const response = await axios.get(baseUrl + `airdrop/?status=${status}&address=${address}`, header);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

const getVSBByStatus = async (status, address = null) => {
	try {
		const response = await axios.get(baseUrl + `vsb/?status=${status}&address=${address}`, header);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

const get730Days = async (address = null) => {
	try {
		const response = await axios.get(baseUrl + "730days/?address=" + address, header);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

const getLearn2Earn = async (status, address = null) => {
	try {
		const response = await axios.get(baseUrl + "l2e/?status=" + status + "&address=" + address, header);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

const getLPFarming = async (status, address = null) => {
	try {
		const response = await axios.get(baseUrl + "lpfarming/?status=" + status + "&address=" + address, header);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

const getPopup = async (page) => {
	try {
		const response = await axios.get(baseUrl + "popup/?page=" + page, header);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

const setSubscribe = async (email) => {
	let payload = new FormData();
	payload.append("email", email);
	return await axios.post(baseUrl + "subscribe", payload, header);
};

const APIHelper = {
	getQuarterly,
	getMonthly,
	getAirdropByStatus,
	getVSBByStatus,
	get730Days,
	getLearn2Earn,
	getLPFarming,
	getPopup,
	setSubscribe,
};

export default APIHelper;
